*{
  margin:0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box
}

.container-fluid{
  width: 100% !important;
  padding: 0;
  
}

html,body{
  height: 100% !important;
}

.form-control{
  color:#fff !important;
}

.header{
  width: 100%;
}

.navbar,.navbar-default{
  width: 100% !important; 
  background-color: #fff !important;
  border:0 !important;
  padding: 10px 50px;
  height: auto;
  border-radius: 0 !important;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2),2px 3px 8px rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2),2px 3px 8px rgba(0, 0, 0, 0.3);
}

.contact-router{
  width: 60%;
}


.logo{
  width: 90px;
  height:90px;
  float: left;
}
.brand{
  margin-top:15px;
  width: 400px;
  height: 65px;
}

.contact-nav{
  color:#354b99 !important;
  margin-top:30px;
  float:right;
}

/* welcome */
.App{
  width: 100%;
  background-color:#354b99;
  color:#fff;
  text-align: center;
  height:100%;
  padding-bottom:9vh;
  background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0,0,0,.6)),to(rgba(0,0,0,0.8))), url('./assets/background.jpg');
  background-image: -webkit-linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,0.8)), url('./assets/background.jpg');
  background-image: -o-linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,0.8)), url('./assets/background.jpg');
  background-image: linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,0.8)), url('./assets/background.jpg');
  background-attachment: fixed;
  background-size: cover;
  font-family: 'lato';
  overflow: hidden;
}

.hero{
  padding:45px;
}

.tagline{
  margin:15vh auto 25px;
  font-weight: bolder;
  font-size: 3.5em;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.9);
}

.about{
  margin:0 auto;
  width:60%;
  color:#fff;
  font-size: 18px;
  font-weight: lighter;
  margin:15px auto;
  margin-bottom: 25px !important;
}

.get-started,.get-started:hover,.get-started:focus{
  text-decoration: none;
  color:#fff;
  border:2px solid #fff;
  border-radius:25px;
  -webkit-box-shadow:5px 5px 5px 0  rgba(0, 0, 0, 0.3);
          box-shadow:5px 5px 5px 0  rgba(0, 0, 0, 0.3);
  padding:5px 15px;
  font-size: 1.25em;
  font-weight: bold;
  text-transform: uppercase;
}

/* second page */
.b{
  display: block;
  margin:15vh auto;
  width: 100%;
  color:#fff;
  border:0;
  position: relative;
  background-color:transparent !important;
  font-family: 'lato' !important;
  font-weight: bold;
  font-size:18px;
  outline:none;
  padding-bottom: 3px;
}

.b:hover,.b:focus{
  text-decoration: none !important;
  color:#fff;
}

.thumbnail{
  display: block;
  margin:1vh auto 0;
  color:#fff;
  background-color: rgba(0, 0, 0, 0.3);
  padding:2px 5px 2px 5px!important;
  border:3px solid #fff;
  overflow-y: scroll;
  height:75vh;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scroll-behavior: smooth;
}
.thumbnail::-webkit-scrollbar {
  display: none;
}

.about-h{
  display: block;
  font-family: lato;
  margin:-15px auto 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.about-p{
  display: block;
  margin:5px auto;
  text-align: center ;
  width: 90% ;
  font-size: 18px;
  font-family: lato;
  font-weight: lighter
}

.about-img{
  width: 500px;
  height: 300px;
  -webkit-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 2px;
}

.solutions-h{
  font-weight: bold;
  font-size: 1.15em;
  text-align: center;
  margin-left:5px;
  font-family: lato;
  text-transform: uppercase;
}

/* contact form */
.form-group{
  margin:10px auto;
  width:80%;
}

.form-control{
  background-color: transparent;
  font-weight: bold;
  padding: 5px;
  border:2px solid #fff;
}

.send{
  background-color:#fff;
  color:rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  font-weight: bold;
  border:0;
  font-size: 18px;
  padding:5px 45px;
  margin-bottom: 15px;
}

.below-form>.solutions-h,.below-form>.about-p{
  margin:10px auto 0 !important;
  text-align: center !important;
}

a,a:hover,a:focus{
  text-decoration: none;
  color:#fff;
}

@media screen and (orientation:landscape) and (max-width:1024px){
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .b{
    margin:0 auto;
    font-size: 14px !important;
  }
  .brand{
    margin-left:-15px;
    margin-top:25px;
    width: 300px;
    height: 35px;
  }
  .App{
    height:100% !important;
  }
  .hero{
    height: 100% !important;
  }
  .thumbnail{
    height: auto !important;
    overflow: hidden;
    padding-bottom: 15px !important;
  }
  .contact-router>.thumbnail{
    height: auto;
    padding-bottom: 15px !important;
  }
}

 /* iphone-4 */

@media screen and (max-width:320px){
  .navbar,.navbar-default{
    height: auto;
    padding: 10px 5px;
  }
  .logo{
    /* margin-left: -20px; */
    width: 65px;
    height: 65px;
  }
  .brand{
    margin-left: -20px;
    width: 140px;
    height: 25px;
  }
  .contact-nav{
    /* margin-left: 45px; */
    margin-top:18px;
  }
  .contact-router>.thumbnail{
    height:auto;
  }
  .hero{
    padding:10px;
    height:100vh;
  }
  .App{
    height:100%;
    padding-bottom:15px;
  }
  .contact-router{
    width: 100%;
  }
  .tagline{
    font-size: 1.3em;   
    margin:5px auto 0;
  }
  .about{
    width:100% !important;
    font-size: 16px;
    text-align: center;
    white-space: normal;
    margin: 25px auto !important;
  }
  .about-h{
    font-size: 21px;
  }
  .solutions-h{
    font-size: 14px;
  }
  .about-p{
    font-size: 16px;
  }
  .reach{
    font-size: 14px;
  }
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .b{
    margin:0 auto;
    font-size: 14px !important;
  }
  .thumbnail{
    margin:15px auto;
    height:auto;
    overflow: hidden;
    padding: 2px 0px 2px 15px !important;
  }
  .about-img{
    width: 255px;
    height: 160px;
  }
  .form{
    width: 100%;
    display: block;
  }
  .form-control{
    height: 35px;
  }
  .form-group{
    width: 100% !important;
    text-align: center;
    margin:10px auto 10px 1px;
    display: block;
  }
  .send{
    padding: 2px 15px;
  }
}

@media screen and (min-width:321px) and (max-width:360px){
  .navbar,.navbar-default{
    height: auto;
    padding: 10px 7px;
  }
  .logo{
    /* margin-left: -25px; */
    width: 65px;
    height: 65px;
  }
  .brand{
    margin-left: -22px;
    margin-top:20px;
    width: 150px;
    height: 30px;
  }
  .contact-nav{
    /* margin-left: 50px; */
    margin-top:17px;
  }
  .contact-router>.thumbnail{
    height:auto;
    margin: 5vh auto;
  }
  .contact-router{
    width: 100%;
  }
  .hero{
    padding:10px;
    height:100vh;
  }
  .App{
    height:100%;
  }
  .tagline{
    font-size: 1.5em;
    margin:25px auto 35px;
  }
  .about{
    width:100% !important;
    font-size: 16px;
    text-align: center;
    white-space: normal;
    margin-bottom: 35px !important;
  }
  .about-h{
    font-size: 21px;
  }
  .solutions-h{
    font-size: 14px;
  }
  .about-p{
    font-size: 16px;
  }
  .reach{
    font-size: 14px;
  }
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .b{
    margin:0 auto;
    font-size: 14px !important;
  }
  .thumbnail{
    margin:15px auto;
    height:auto;
    overflow: hidden;
    padding: 2px 0px 2px 15px !important;
  }
  .about-img{
    width: 255px;
    height: 160px;
  }
  .form{
    width: 100%;
    display: block;
  }
  .form-control{
    height: 35px;
  }
  .form-group{
    width: 100% !important;
    text-align: center;
    margin:10px auto 10px 1px;
    display: block;
  }
  .send{
    padding: 2px 15px;
  }
}

@media screen and (min-width:361px) and (max-width:375px){
  .navbar,.navbar-default{
    height: auto;
    padding: 10px 7px;
  }
  .logo{
    /* margin-left: -15px; */
    width: 65px;
    height: 65px;
  }
  .brand{
    margin-left: -22px;
    width: 160px;
    height: 30px;
  }
  .contact-nav{
    /* margin-left: 35px; */
    margin-top:22px;
  }
  .contact-router{
    width: 100%;
  }
  .contact-router>.thumbnail{
    height:auto;
    margin:5vh auto 12vh;
  }
  .hero{
    padding:10px;
    height:100vh;
  }
  .App{
    height:100%;
  }
  .tagline{
    font-size: 1.5em;
    margin:10vh auto 25px;
  }
  .about{
    width:100% !important;
    font-size: 16px;
    text-align: center;
    white-space: normal;
    margin-bottom: 35px !important;
  }
  .about-h{
    font-size: 21px;
  }
  .solutions-h{
    font-size: 14px;
  }
  .about-p{
    font-size: 16px;
  }
  .reach{
    font-size: 14px;
  }
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .b{
    margin:0 auto;
    font-size: 16px !important;
  }
  .thumbnail{
    margin:15px auto;
    height:auto;
    overflow: hidden;
    padding: 2px 0px 2px 10px !important;
  }
  .about-img{
    width: 290px;
    height: 180px;
    margin-left: 7px;
  }
  .form{
    width: 100%;
    display: block;
  }
  .form-control{
    height: 35px;
  }
  .form-group{
    width: 100% !important;
    text-align: center;
    margin:10px auto 10px 4px;
    display: block;
  }
  .send{
    padding: 2px 15px;
  }
}

@media screen and (device-width:375px) and (device-height:812px){
  .logo{
    width: 70px;
    height:70px;
  }
  .contact-nav{
    margin-top:20px;
  }
  .tagline{
    font-size: 1.6em;
  }
  .about{
    font-size: 16px;
  }
  .contact-thumb{
    margin:10vh auto 12vh;
  }
  .about-p{
    font-size: 18px;
  }
  .b{
    font-size: 18px;
  }
  .tagline{
    margin-top:20vh
  }
}

@media screen and (min-width:376px) and (max-width:384px){
  .navbar,.navbar-default{
    height: auto;
    padding: 10px 7px;
  }
  .logo{
    /* margin-left: -15px; */
    width: 70px;
    height: 70px;
  }
  .brand{
    margin-left: -20px;
    width: 150px;
    height: 30px;
  }
  .contact-nav{
    margin-top:20px;
  }
  .contact-router>.thumbnail{
    height:auto;
    margin:5vh auto;
  }
  .contact-router{
    width: 100%;
  }
  .hero{
    padding:10px;
    height:100vh;
  }
  .App{
    height:100%;
  }
  .tagline{
    font-size: 1.6em;
    margin:15vh auto 0;
  }
  .about{
    width:100% !important;
    font-size: 16px;
    text-align: center;
    white-space: normal
  }
  .about-h{
    font-size: 21px;
  }
  .solutions-h{
    font-size: 14px;
  }
  .about-p{
    font-size: 16px;
  }
  .reach{
    font-size: 14px;
  }
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .b{
    margin:0 auto;
    font-size: 16px !important;
  }
  .thumbnail{
    margin:15px auto ;
    height:auto;
    overflow: hidden;
    padding: 2px 0px 2px 10px !important;
  }
  .about-img{
    width: 290px;
    height: 180px;
    margin-left: 7px;
  }
  .form{
    width: 100%;
    display: block;
  }
  .form-control{
    height: 35px;
  }
  .form-group{
    width: 100% !important;
    text-align: center;
    margin:10px auto 10px 4px;
    display: block;
  }
  .send{
    padding: 2px 15px;
  }
}

@media screen and (min-width:385px) and (max-width:414px){
  .navbar,.navbar-default{
    height: auto;
    padding: 10px 8px;
  }
  .logo{
    width: 85px;
    height: 85px;
  }
  .brand{
    margin-left: -22px;
    margin-top:25px;
    width: 180px;
    height: 35px;
  }
  .contact-nav{
    margin-top:28px;
  }
  .contact-router>.thumbnail{
    height:auto;
    margin:10vh auto;
    overflow: hidden;
  }
  .contact-router{
    width: 100%;
  }
  .hero{
    padding:10px;
    height:100vh;
  }
  .App{
    height:100%;
  }
  .get-started{
    font-size: 16px !important;
  }
  .tagline{
    font-size: 1.7em;
    margin:20vh auto 25px;
  }
  .about{
    width:100% !important;
    font-size: 16px;
    text-align: center;
    white-space: normal;
    margin-bottom: 35px !important;
  }
  .about-h{
    font-size: 21px;
  }
  .solutions-h{
    font-size: 14px;
  }
  .about-p{
    font-size: 18px;
  }
  .reach{
    font-size: 14px;
  }
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .b{
    margin:0 auto;
    font-size: 16px !important;
  }
  .thumbnail{
    margin:15px auto;
    height:auto;
    overflow: hidden;
    padding: 2px 0px 2px 10px !important;
  }
  .about-img{
    width: 290px;
    height: 180px;
    margin-left: 7px;
  }
  .form{
    width: 100%;
    display: block;
  }
  .form-control{
    height: 35px;
  }
  .form-group{
    width: 100% !important;
    text-align: center;
    margin:10px auto 10px 4px;
    display: block;
  }
  .send{
    padding: 2px 15px;
  }
}

@media screen and (min-width:415px) and (max-width:480px){
  .navbar,.navbar-default{
    height: auto;
    padding:10px;
  }
  .logo{
    width: 85px;
    height: 85px;
  }
  .brand{
    margin-left: -35px;
    margin-top:25px;
    width: 220px;
    height: 35px;
  }
  .contact-nav{
    margin-top:29px;
  }
  .contact-router>.thumbnail{
    height:auto;
    overflow: hidden;
    margin: 8vh auto;
  }
  .contact-router{
    margin:0 auto;
    width: 90%;
  }
  .hero{
    padding:10px;
    height:100vh;
  }
  .App{
    height:100%;
  }
  .tagline{
    font-size: 2em;
    margin:20vh auto 25px;
  }
  .about{
    width:100% !important;
    font-size: 18px;
    text-align: center;
    white-space: normal;
    margin-bottom: 35px !important;
  }
  .about-h{
    font-size: 21px;
  }
  .solutions-h{
    font-size: 14px;
  }
  .about-p{
    font-size: 21px;
  }
  .reach{
    font-size: 14px;
  }
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .b{
    margin:0 auto;
    font-size: 18px !important;
  }
  .thumbnail{
    margin:20px auto;
    height:auto;
    overflow: hidden;
    padding: 2px 0px 2px 10px !important;
  }
  .about-img{
    width: 90%;
    height: 250px;
    margin-left: 7px;
  }
  .form{
    width: 100%;
    margin-top:30px;
    display: block;
  }
  .form-control{
    height: 35px;
  }
  .form-group{
    width: 100% !important;
    text-align: center;
    margin:10px auto 10px 4px;
    display: block;
  }
  .send{
    padding: 4px 20px;
  }
}

@media screen and (min-width:481px) and (max-width:767px){
  .navbar,.navbar-default{
    height: auto;
    padding:10px;
  }
  .logo{
    width: 95px;
    height: 95px;
  }
  .brand{
    margin-left: -30px;
    margin-top:25px;
    width: 270px;
    height: 50px;
  }
  .contact-nav{
    margin-top:30px;
    font-size:2.5em;
  }
  .about-thumb,.product-thumb,.contact-thumb,.contact-router>.thumbnail{
    overflow: hidden;
  }
  .contact-router>.thumbnail{
    height:80vh;
  }
  .contact-router{
    margin:5px auto;
    width: 90%;
  }
  .hero{
    padding:10px;
  }
  .App{
    height:100vh;
  }
  .tagline{
    font-size: 2.6em;
    margin:25vh auto 25px;
  }
  .about{
    width:100% !important;
    font-size: 18px;
    text-align: center;
    white-space: normal;
    margin-bottom: 35px !important;
  }
  .about-h{
    font-size: 21px;
  }
  .solutions-h{
    font-size: 14px;
  }
  .about-p{
    font-size: 21px;
  }
  .reach{
    font-size: 14px;
  }
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .b{
    margin:0 auto;
    font-size: 18px !important;
  }
  .get-started,.get-started:hover,.get-started:focus{
    padding:10px 20px;
  }
  .thumbnail{
    margin:50px auto 0;
    height:70vh;
    padding: 2px 0px 2px 10px !important;
  }
  .about-img{
    width: 90%;
    height: 300px;
    margin-left: 7px;
  }
  .form{
    width: 100%;
    margin-top:30px;
    display: block;
  }
  .form-control{
    height: 35px;
  }
  .form-group{
    width: 100% !important;
    text-align: center;
    margin:10px auto 10px 4px;
    display: block;
  }
  .send{
    padding: 4px 20px;
  }
}


@media screen and (min-width:768px) and (max-width:992px) and (orientation:portrait){
  .navbar,.navbar-default{
    height: auto;
    padding: 10px 15px;
  }
  .logo{
    width: 100px;
    height: 100px;
  }
  .get-started,.get-started:hover,.get-started:focus{
    padding:10px 20px;
  }
  .about-thumb,.product-thumb,.contact-thumb,.contact-router>.thumbnail{
    overflow: hidden;
  }
  .brand{
    margin-left: -10px;
    margin-top:30px;
    width: 250px;
    height: 40px;
  }
  .contact-nav{
    margin-top:32px;
    font-size: 2.5em
  }
  .contact-router{
    width: 90%;
  }
  .hero{
    padding:10px;
  }
  .App{
    height:100vh;
  }
  .tagline{
    font-size: 3.2em;
    margin:28vh auto 25px;
  }
  .about{
    width:100% !important;
    font-size: 21px;
    text-align: center;
    white-space: normal;
    margin-bottom: 35px !important
  }
  .about-h{
    font-size: 24px;
  }
  .solutions-h{
    font-size: 18px;
  }
  .about-p{
    font-size: 21px;
  }
  .reach{
    font-size: 18px;
  }
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .b{
    margin:0 auto;
    font-size: 21px !important;
  }
  .thumbnail{
    margin:35px auto 0;
    height:70vh;
    padding: 2px 0px 2px 10px !important;
  }
  .about-img{
    width: 90%;
    height: 300px;
    margin-left: 7px;
  }
  .form{
    width: 100%;
    display: block;
  }
  .form-control{
    height: 45px;
    font-size: 21px;
  }
  .form-group{
    width: 100% !important;
    text-align: center;
    margin:10px auto 10px 4px;
    display: block;
  }
  .send{
    padding: 4px 20px;
  }
}

@media screen and (device-width:1024px) and (device-height:1366px){
  .navbar,.navbar-default{
    height: auto;
    padding:10px 25px !important;
  }
  .logo{
    width: 130px !important;
    height: 130px !important;
  }
  .brand{
    /* margin-left: -10px; */
    margin-top:40px !important;
    width: 350px !important;
    height: 50px !important;
  }
  .contact-nav{
    margin-top:40px !important;
    font-size: 3em;
  }
  .hero{
    padding:10px;
  }
  .contact-router{
    width: 90%;
  }
  .contact-router>.thumbnail{
    height: 100%;
    margin:20vh auto
  }
  .App{
    height:100vh;
  }
  .tagline{
    font-size: 4.5em !important;
    margin:30vh auto 25px !important;
  }
  .about{
    width:100% !important;
    font-size: 24px !important;
    text-align: center;
    white-space: normal;
    margin-bottom: 35px !important;
  }
  .about-h{
    font-size: 28px;
  }
  .solutions-h{
    font-size: 18px;
  }
  .about-p{
    font-size: 24px;
  }
  .reach{
    font-size: 21px;
  }
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .get-started,.get-started:hover,.get-started:focus{
    padding:10px 20px;
  }
  .b{
    margin:0 auto;
    font-size: 21px !important;
  }
  .thumbnail{
    margin:35px auto 0;
    height:70vh;
    padding: 2px 0px 2px 10px !important;
  }
  .about-img{
    width: 90%;
    height: 400px;
    margin-left: 7px;
  }
  .form{
    width: 100%;
    display: block;
  }
  .form-control{
    height: 45px;
    font-size: 21px;
  }
  .form-group{
    width: 100% !important;
    text-align: center;
    margin:10px auto 10px 4px;
    display: block;
  }
  .send{
    padding: 4px 20px;
  }
}

@media screen and (min-width:993px) and (max-width:1199px){
  .navbar,.navbar-default{
    height: auto;
    padding: 10px 25px;
  }
  .logo{
    width: 100px;
    height: 100px;
  }
  .brand{
    margin-left: -10px;
    margin-top:30px;
    width: 300px;
    height: 45px ;
  }
  .contact-nav{
    margin-top:35px;
  }
  .logo{
    /* margin: -2px auto 0; */
    width: 100px;
    height: 100px;
  }
  .contact-router{
    width: 70%;
  }
  .contact-router>.thumbnail{
    height: 80vh;
  }
  .hero{
    padding:10px;
  }
  .brand{
    width: 300px;
    height: 43px;
  }
  .App{
    height:100vh;
  }
  .tagline{
    font-size: 4.2em;
    margin:20vh auto 25px;
  }
  .about{
    width:80% !important;
    font-size: 21px;
    text-align: center;
    white-space: normal;
    margin-bottom: 35px !important;
  }
  .about-h{
    font-size: 24px;
  }
  .solutions-h{
    font-size: 18px;
  }
  .about-p{
    font-size: 21px;
  }
  .reach{
    font-size: 21px;
  }
  .bu{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align:space-between;
        align-items:space-between;
    margin:0;
  }
  .b{
    margin:0 auto;
    font-size: 21px !important;
  }
  .thumbnail{
    margin:10px auto 0;
    height:70vh;
    padding: 2px 0px 2px 10px ;
  }
  .about-img{
    width: 70%;
    height: 350px;
    margin-left: 7px;
  }
  .form{
    width: 100%;
    display: block;
  }
  .form-control{
    height: 45px;
    font-size: 18px;
  }
  .form-group{
    width: 90% !important;
    text-align: center;
    margin:10px auto ;
    display: block;
  }
  .send{
    padding: 4px 20px;
  }
}

@media screen and (min-width:1440px){
  .tagline{
    margin-top:20vh;
    font-size: 4.5em;
  }
  .App{
    height:100vh;
  }
  .b{
    font-size: 2em;
    margin:18vh auto;
  }
  .thumbnail{
    padding-top: 15px !important;
    height:80vh;
    margin:2vh auto;
  }
  .reach{
    margin:25px auto;
    font-size: 21px !important;
    font-family: lato;
  }
  .about{
    font-size: 21px;
    width: 70%;
    margin-bottom: 25px !important;
  }
  .get-started{
    font-size: 21px;
  }
  .about-p{
    font-size:21px;
    width: 90%;
  }
  .form{
    margin:5vh auto;
  }
  .form-control{
    height: 35px;
  }
  .about-img{
    width: 500px;
    height: 300px;
    -webkit-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
    margin: 25px auto;
  }
}

@media screen and (min-width:1920px){
  .navbar,.navbar-default{
    padding:10px 50px;
    height: auto;
  }
  .logo{
    width: 100px;
    height:100px;
  }
  .brand{
    height: 60px;
    width:400px;
  }
  .contact-nav{
    font-size: 3em;
  }
  .tagline{
    font-size: 6em;
    margin-top:22vh;
  }
  .about-p,.about{
    font-size: 24px;
  }
  .contact-router{
    width: 60% !important;
  }
  .bu{
    font-size: 18px;
  }
  .form-control{
    height:40px;
    font-size: 18px;
    margin:10px auto 15px;
  }
  .container{
    width: 70%;
  }
  .about-img{
    width: 500px;
    height: 300px;
    -webkit-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
    margin: 45px auto;
  }
}

@media screen and (min-width:2560px){
  .navbar,.navbar-default{
    height: 130px;
  }
  .logo{
    width: 110px;
    height: 110px;
  }
  .brand{
    margin-top: 25px;
    width: 400px;
    height: 55px;
  }
  .contact-router>.thumbnail{
    height:  70vh !important;
  }
  .contact-nav{
    margin-top:35px; 
  }
  .about-p{
    font-size: 32px;
  }
  .tagline{
    font-size:8em;
    margin-top:30vh;
  }
  .form-control{
    height: 50px;
    font-size: 18px;
  }
  .thumbnail{
    margin: 5vh auto;
  }
  .about{
    font-size: 32px;
    margin-bottom: 25px !important;
  }
  .about-h{
    font-size: 3em;
    margin: 15px auto;
  }
  .solutions-h{
    font-size: 2em;
  }
  .get-started{
    font-size: 28px;
    padding: 10px 20px;
  }
  .bu{
    font-size: 18px;
  }
  .container{
    width: 70%;
  }
  .form-control{
    height: 40px;
  }
  .about-img{
    width: 800px;
    height: 500px;
    -webkit-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
    margin: 45px auto;
  }
}



.mapouter{
  margin: 25px auto !important;
  display: block !important;
  text-align:right;
  height:300px;
  width:300px;
}
.gmap_canvas {
  overflow:hidden;
  background:none!important;
  height:300px;
  width:300px;
}